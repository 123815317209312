<template>
  <CModal
    :show.sync="boo_modal"
    :no-close-on-backdrop="true"
    :centered="false"
    title="Modal title 2"
    :size="'xl'"
    color="warning"
  >
    <CRow>
      <CCol lg="12" md="12" sm="12" xs="12">
        <label class="radio-input">
          <input
            :id="'type'"
            type="radio"
            :value="'1'"
            v-model="expense.type"
            @input="mtd_change_type"
          />
          Otros gastos
        </label>
        <label class="radio-input pl-3">
          <input
            :id="'type'"
            type="radio"
            :value="'2'"
            v-model="expense.type"
            @input="mtd_change_type"
          />
          Gastos sev personal
        </label>
      </CCol>
    </CRow>
    <div>
      <CRow>
        <CCol lg="12" md="12" sm="12" xs="12">
          <label for="">Proveedor</label>
          <v-select
            :options="data_supplier"
            v-model="expense.supplier"
            placeholder="Seleccione proveedor"
          />
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol lg="3" md="3" sm="12" xs="12">
          <label for="">Tipo de servicio</label>
          <v-select
            :options="data_type_serv"
            v-model="expense.type_serv"
            placeholder="Seleccione proveedor"
            :disabled="expense.type == 1 ? true : false"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <CInput
            label="Monto bruto"
            v-model="expense.amount_bruto"
            placeholder="Monto bruto"
            v-on:keypress="isNumber($event)"
            @blur="mtd_out_focus"
            :disabled="expense.type == 1 ? true : false"
          ></CInput>
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <CInput
            label="Impuesto"
            v-model="expense.impuesto"
            placeholder="Impuesto"
            v-on:keypress="isNumber($event)"
            @blur="mtd_out_focus"
            :disabled="expense.type == 1 ? true : false"
          ></CInput>
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12" v-if="expense.type == 1">
          <CInput
            label="Monto neto pago"
            v-model="expense.amount"
            placeholder="Monto neto de pago"
            v-on:keypress="isNumber($event)"
            @blur="mtd_out_focus"
          ></CInput>
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12" v-else>
          <CInput
            label="Monto neto pago"
            v-model="cp_amount"
            placeholder="Monto neto de pago"
            v-on:keypress="isNumber($event)"
            @blur="mtd_out_focus"
            :disabled="true"
          ></CInput>
        </CCol>
        <CCol lg="4" md="4" sm="12" xs="12">
          <CInput
            label="N° RH"
            v-model="expense.rh"
            placeholder="RH"
            :disabled="expense.type == 1 ? true : false"
          ></CInput>
        </CCol>
        <CCol lg="4" md="4" sm="12" xs="12">
          <label for="">Método pago</label>
          <v-select
            :options="data_method_pyment"
            v-model="expense.method_payment"
            placeholder="Seleccione un metodo"
          />
        </CCol>
        <CCol lg="4" md="4" sm="12" xs="12">
          <CInput label="Fecha" v-model="expense.date" type="date"></CInput>
        </CCol>
        <CCol lg="12" md="12" sm="12" xs="12">
          <CTextarea
            label="Descripción"
            placeholder="Descripción del egreso"
            v-model="expense.description"
          ></CTextarea>
        </CCol>
      </CRow>
    </div>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close_modal_expense" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close_modal_expense" color="danger">Cancelar</CButton>
      <CButton
        @click="mtd_commit_modal_expense"
        color="warning"
        :disabled="cp_button_save"
        >Registrar</CButton
      >
    </template>
  </CModal>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../../main";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "c-modal-income",
  components: { vSelect },
  props: {
    title: String,
    boo_modal: Boolean,
    item: {},
    data_supplier: [],
    size: String,
    bead: {},
  },
  data() {
    return {
      view: null,
      data_type_serv: [
        { value: "PERMANENTE", label: "PERMANENTE" },
        { value: "EVENTUAL", label: "EVENTUAL" },
      ],
      data_method_pyment: [
        { value: 1, label: "Efectivo" },
        { value: 2, label: "Tarjeta" },
        { value: 3, label: "Transferencia" },
        { value: 4, label: "Cheque" },
      ],
      expense: {
        bead_id: null,
        type: 1,
        supplier: null,
        type_serv: null,
        amount_bruto: "",
        impuesto: "",
        amount: "",
        rh: "",
        method_payment: null,
        date: null,
        description: "",
      },
    };
  },
  computed: {
    cp_amount: function () {
      let res = 0;
      if (this.expense.amount_bruto > 0 && this.expense.impuesto > 0) {
        res =
          parseFloat(this.expense.amount_bruto) -
          parseFloat(this.expense.impuesto);
      }
      this.expense.amount = parseFloat(res).toFixed(2);
      return this.expense.amount;
    },
    cp_button_save: function () {
      if (
        this.expense.type != null &&
        this.expense.supplier != null &&
        this.expense.description.length > 0
      )
        return false;
      return true;
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_close_modal_expense() {
      this.$emit("mtd_close_modal_expense");
    },
    mtd_commit_modal_expense() {
      this.expense.bead_id = this.bead.id;
      this.post({
        url: this.$store.getters.get__url + "/bead/store/expense",
        token: this.$store.getters.get__token,
        params: this.expense,
      })
        .then((response) => {
          if (response.state == 0) {
            this.expense= {
              bead_id: null,
              type: 1,
              supplier: null,
              type_serv: null,
              amount_bruto: "",
              impuesto: "",
              amount: "",
              rh: "",
              method_payment: null,
              date: null,
              description: "",
            };
            bus.$emit("alert", {
              color: "success",
              message: "GUARDADO CORRECTAMENTE!!",
            });
            this.$emit("mtd_commit_modal_expense", response.expense);
          } else {
            bus.$emit("alert", {
              color: "success",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "success",
            message: errors,
          });
        });
    },
    mtd_out_focus: function () {
      this.expense.amount_bruto =
        this.expense.amount_bruto > 0
          ? parseFloat(this.expense.amount_bruto).toFixed(2)
          : 0.0;
      this.expense.impuesto =
        this.expense.impuesto > 0
          ? parseFloat(this.expense.impuesto).toFixed(2)
          : 0.0;
      this.expense.amount =
        this.expense.amount > 0
          ? parseFloat(this.expense.amount).toFixed(2)
          : 0.0;
    },
    mtd_change_type: function () {
      this.expense.amount_bruto = "";
      this.expense.impuesto = "";
      this.expense.amount = "";
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>