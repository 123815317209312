<template>
  <CModal
    :show.sync="boo_modal"
    :no-close-on-backdrop="true"
    :centered="false"
    title="Modal title 2"
    :size="'xl'"
    color="warning"
  >
    <CRow>
      <CCol lg="12" md="12" sm="12" xs="12"  class="position-relative table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Met. pago</th>
              <th>Descripción</th>
              <th>Proveedor</th>
              <th>Fecha pago</th>
              <th>Tipo Serv.</th>
              <th>Monto bruto</th>
              <th>Impuesto</th>
              <th>Monto</th>
              
            </tr>
          </thead>
          <tbody v-if="charges.length > 0">
            <tr v-for="(item, index) in charges" :key="index">
              <td>{{index +1 }} </td>
              <td>{{ item.method }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.supplier }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.type_serv }}</td>
              <td>{{item.amount_bruto}} </td>
              <td>{{item.impuesto}} </td>
              <td>{{item.amount}} </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="9">NO EXISTEN REGISTROS PARA MOSTRAR</td>
            </tr>
          </tbody>
        </table>
      </CCol>
    </CRow>

    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="warning">Cerrar</CButton>
      <!-- <CButton @click="mtd_commit" color="info">Aceptar</CButton> -->
    </template>
  </CModal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "c-bead-charge",
  components: {},
  data() {
    return {};
  },
  props: {
    title: String,
    boo_modal: Boolean,
    charges: [],
  },
  computed: {},
  methods: {
    ...mapActions(["get", "post"]),
    mtd_close() {
      this.$emit("mtd_close_modal_view_expense");
    },
    // mtd_commit() {
    //   this.$emit("mtd_commit_modal_charge");
    // },
  },
};
</script>

<style lang="scss" scoped>
</style>