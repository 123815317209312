<template>
  <CModal
    :show.sync="boo_modal"
    :no-close-on-backdrop="true"
    :centered="false"
    title="Modal title 2"
    :size="'xl'"
    color="warning"
  >
    <CRow>
      <CCol lg="12" md="12" sm="12" xs="12">
        <label class="radio-input">
          <input
            :id="'type'"
            type="radio"
            :value="'2'"
            v-model="advancement.type"
            @input="mtd_change_type"
          />
          Adelantos
        </label>
        <label class="radio-input pl-3">
          <input
            :id="'type'"
            type="radio"
            :value="'1'"
            v-model="advancement.type"
            @input="mtd_change_type"
          />
          Pagar
        </label>
      </CCol>
    </CRow>

    <CRow v-if="view == 'advancement'">
      <CCol lg="8" md="8" sm="12" xs="12">
        <label for="">Proveedor socio/no socio</label>
        <v-select
          :options="data_supplier_all"
          v-model="advancement.supplier"
          placeholder="Seleccione proveedor"
        />
      </CCol>
      <CCol lg="2" md="2" sm="12" xs="12">
        <label for="">Método pago</label>
        <v-select
          :options="data_method_pyment"
          v-model="advancement.method_payment"
          placeholder="Seleccione un metodo"
        />
      </CCol>
      <CCol lg="1" md="1" sm="12" xs="12">
        <CInput
          label="Monto"
          v-model="advancement.amount"
          placeholder="Monto"
          v-on:keypress="isNumber($event)"
          @blur="mtd_out_focus"
        ></CInput>
      </CCol>
      <CCol lg="1" md="1" sm="12" xs="12" class="mt-2">
        <br />
        <CButton
          @click="mtd_commit_modal_advancement"
          color="warning"
          :disabled="cp_button_save"
          v-c-tooltip.hover="{
            content: 'Registrar',
          }"
          ><i class="fas fa-save"></i>
        </CButton>
      </CCol>
    </CRow>
    <div v-else-if="view == 'payment'">
      <CRow v-if="viewPayment == 'table'">
        <CCol lg="12" md="12" sm="12" xs="12">
          <CInput
            label=""
            v-model="search"
            placeholder="BUSQUE POR DOCUMENTO O NOMBRE DEL PROVEEDOR"
            @keypress.enter="mtd_search_data"
          ></CInput>
        </CCol>
        <CCol
          lg="12"
          md="12"
          sm="12"
          xs="12"
          class="position-relative table-responsive"
        >
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Proveedor</th>
                <th>Serie</th>
                <th>Peso neto</th>
                <th>Monto</th>
                <!-- <th>Adelanto</th> -->
                <!-- <th>Monto Total</th> -->
                <th>Monto Pagado</th>
                <th>Saldo</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="lst.length > 0">
              <tr v-for="(item, index) in lst" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.supplier }}</td>
                <td>{{ item.serie }}</td>
                <td>{{ item.neto }}</td>
                <td>{{ parseFloat(item.liq).toFixed(2) }}</td>
                <!-- <td>{{ parseFloat(item.advancement).toFixed(2) }}</td> -->
                <!-- <td>{{ parseFloat(item.total).toFixed(2) }}</td> -->
                <td>{{ parseFloat(item.pagado).toFixed(2) }}</td>
                <td>{{ parseFloat(item.saldo).toFixed(2) }}</td>
                <td>
                  <CButton @click="mtd_set_payment(item)" color="info"
                    ><i class="fas fa-save"></i
                  ></CButton>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" colspan="9">
                  NO EXISTEN REGISTROS PARA MOSTRAR
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
      <CRow v-else>
        <CCol lg="12" sm="12" md="12" xs="12" class="text-left">
          <CButton size="sm" color="warning" @click="mtd_return_table">
            <i class="fas fa-angle-left"></i> Regresar
          </CButton>
        </CCol>
        <CCol lg="2" md="2" sm="6" xs="12">
          <CInput
            label="Pendiente"
            :disabled="true"
            v-model="payment.pending"
          />
        </CCol>
        <CCol lg="2" md="2" sm="6" xs="12">
          <CInput
            label="Monto a pagar"
            v-model="payment.amount"
            placeholder="Monto a pagar"
            v-on:keypress="isNumber($event)"
          />
        </CCol>
        <CCol lg="2" md="2" sm="6" xs="12">
          <CInput
            label="Saldo"
            :disabled="true"
            v-model="cp_saldo"
            v-on:keypress="isNumber($event)"
          />
        </CCol>
        <CCol lg="2" md="2" sm="6" xs="12">
          <CInput
            label="Adelanto"
            :disabled="true"
            v-model="payment.advancement"
          />
        </CCol>
        <CCol lg="2" md="2" sm="6" xs="12">
          <CInput
            label="Descuento"
            placeholder="Descuento"
            v-model="payment.advancement_payment"
            :disabled="payment.advancement > 0 ? false:true"
          />
        </CCol>
        <CCol lg="2" md="2" sm="6" xs="12">
          <CInput
            label="Pend. Dsc."
            :disabled="true"
            v-model="cp_advancement_pending"
          />
        </CCol>
        <CCol lg="2" md="2" sm="6" xs="12">
          <CInput
            label="Total pago"
            :disabled="true"
            v-model="cp_total_pago"
            placeholder="Total pago"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <label for="">Método pago</label>
          <v-select
            :options="data_method_pyment"
            v-model="payment.method_payment"
            placeholder="Seleccione un metodo"
          />
        </CCol>
        <CCol lg="2" md="2" sm="6" xs="12">
          <CInput
            label="Num. operación"
            placeholder="Num. operación"
            :disabled="cp_num_op"
            v-model="payment.num_ope"
            v-on:keypress="isNumber($event)"
          />
        </CCol>
        <CCol lg="1" md="1" sm="12" xs="12" class="mt-2">
          <br />
          <CButton
            @click="mtd_commit_modal_advancement"
            color="warning"
            :disabled="cp_button_save"
            v-c-tooltip.hover="{
              content: 'Registrar',
            }"
            ><i class="fas fa-save"></i>
          </CButton>
        </CCol>
      </CRow>
    </div>
    <div v-else>
      <CRow>
        <CCol lg="12" md="12" sm="12" xs="12" class="text-center">
          <h3>Seleccione una opción</h3>
        </CCol>
      </CRow>
    </div>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close_modal_advancement" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close_modal_advancement" color="danger"
        >Cerrar</CButton
      >
    </template>
  </CModal>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../../main";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "c-modal-income",
  components: { vSelect },
  props: {
    title: String,
    boo_modal: Boolean,
    item: {},
    data_supplier_all: [],
    lst_acopios: [],
    size: String,
    bead: {},
  },
  data() {
    return {
      view: "advancement",
      data_method_pyment: [
        { value: 1, label: "Efectivo" },
        { value: 2, label: "Tarjeta" },
        { value: 3, label: "Transferencia" },
        { value: 4, label: "Cheque" },
      ],
      advancement: {
        bead_id: null,
        type: 2,
        supplier: null,
        method_payment: null,
      },
      lst_res: [],
      lst: [],
      search: "",
      viewPayment: "table",
      payment: {
        bead_id: "",
        acopio_id: "",
        advancement:"",
        advancement_payment:"",
        advancement_pending:"",
        pending: "",
        amount: "",
        method_payment: null,
        num_ope: "",
        total_payment:""
      },
    };
  },
  computed: {
    cp_button_save: function () {
      if (this.view == "advancement") {
        if (this.advancement.supplier != null && this.advancement.amount > 0)
          return false;
        return true;
      } else {
        if (this.payment.amount > 0) {
          if (
            this.payment.method_payment != null &&
            this.payment.method_payment.value == 1
          ) {
            return false;
          } else if (
            this.payment.method_payment != null &&
            this.payment.method_payment.value != 1
          ) {
            if (this.payment.num_ope.length > 0) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    },
    cp_num_op: function () {
      if (this.payment.method_payment == null) return true;
      if (this.payment.method_payment.value == 1) return true;
      return false;
    },
    cp_advancement_pending: function(){
      if (this.payment.advancement_payment > 0) {
          let res = parseFloat(this.payment.advancement - this.payment.advancement_payment);
          return parseFloat(res).toFixed(2);
      } else {
        return this.payment.advancement;
      }
    },
    cp_saldo: function () {
      let res = 0;
      if (this.payment.amount > 0) {
        res = parseFloat(this.payment.pending - this.payment.amount);
      }
      return parseFloat(res).toFixed(2);
    },
    cp_total_pago:function(){
      if (this.payment.advancement > 0) {
        if (this.payment.amount > 0 && this.payment.advancement_payment > 0) {
          this.payment.total_payment = parseFloat(this.payment.amount - this.payment.advancement_payment).toFixed(2);
        }else{
          this.payment.total_payment = 0;
        }
      } else {
        this.payment.total_payment =this.payment.amount;
      }
      return this.payment.total_payment;
    }
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_close_modal_advancement() {
      this.$emit("mtd_close_modal_advancement", this.lst_res,this.lst_acopios);
    },
    mtd_commit_modal_advancement() {
      this.advancement.bead_id = this.bead.id;
      let url = "",
        payload = null;
      if (this.view == "advancement") {
        url = this.$store.getters.get__url + "/bead/store/advancement";
        payload = this.advancement;
      } else {
        url = this.$store.getters.get__url + "/bead/store/paymentacopio";
        payload = this.payment;
      }
      this.post({
        url: url,
        token: this.$store.getters.get__token,
        params: payload,
      })
        .then((response) => {
          if (response.state == 0) {
            if (this.view == "advancement") {
              this.advancement = {
                bead_id: null,
                type: 2,
                supplier: null,
                method_payment: null,
              };
            }else{
              this.viewPayment = "table";
              this.lst_acopios.forEach((element) => {
                if (element.id_acopio === this.payment.acopio_id) {
                  element.pagado =  parseFloat(element.pagado +  this.payment.amount).toFixed(2);
                  element.saldo = parseFloat(element.saldo -  this.payment.amount).toFixed(2);
                }
              });
              this.mtd_search_data();
              this.payment= {
                bead_id: "",
                acopio_id: "",
                advancement:"",
                advancement_payment:"",
                advancement_pending:"",
                pending: "",
                amount: "",
                method_payment: null,
                num_ope: "",
                total_payment:""
              };
            }
            this.lst_res.push(response.res);
            bus.$emit("alert", {
              color: "success",
              message: response.message,
            });
          } else {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "success",
            message: errors,
          });
        });
    },
    mtd_out_focus: function () {
      if (this.view == "advancement") {
        this.advancement.amount =
          this.advancement.amount > 0
            ? parseFloat(this.advancement.amount).toFixed(2)
            : 0.0;
      }
      //   this.expense.amount_bruto =
      //     this.expense.amount_bruto > 0
      //       ? parseFloat(this.expense.amount_bruto).toFixed(2)
      //       : 0.0;
      //   this.expense.impuesto =
      //     this.expense.impuesto > 0
      //       ? parseFloat(this.expense.impuesto).toFixed(2)
      //       : 0.0;
      //   this.expense.amount =
      //     this.expense.amount > 0
      //       ? parseFloat(this.expense.amount).toFixed(2)
      //       : 0.0;
    },
    mtd_change_type: function () {
      if (this.advancement.type == 1) {
        this.view = "advancement";
      } else {
        this.view = "payment";
      }
    },
    /*** pagos acopios */
    mtd_search_data: function () {
      if (this.search.length > 0 && this.search != "") {
        let q = this.search.toString().toUpperCase();
        this.lst = [];
        this.lst_acopios.forEach((element) => {
          let name = element.supplier.toString();
          if (name.includes(q) === true) {
            this.lst.push(element);
          }
        });
      } else {
        this.lst = []; //this.data_product;
      }
    },
    mtd_set_payment: function (item) {
      this.viewPayment = "payment";
      this.payment.bead_id = this.bead.id;
      this.payment.advancement = item.advancement;
      this.payment.acopio_id = item.id_acopio;
      this.payment.pending = item.saldo;
      this.payment.amount = "";
      this.payment.method_payment = null;
      this.payment.num_ope = "";
    },
    mtd_return_table: function () {
      this.viewPayment = "table";
      this.payment = {
        bead_id: "",
        acopio_id: "",
        pending: "",
        amount: "",
        method_payment: null,
        num_ope: "",
      };
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>