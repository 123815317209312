<template>
  <div>
    <div v-if="bead == null">
      <CRow>
        <CCol sm="12">
          <CTableWrapper
            :items="data"
            :fields="fields"
            hover
            :striped="true"
            :border="true"
            small
            fixed
            caption="Lista de cajas"
            icon="fas fa-cash-register"
            btn_name=""
            :size="'md'"
            :button_new="false"
            :actions="true"
            :withActions="'6%'"
            :buttonEdit="false"
            :buttonDelete="false"
            :myButtons="myButtons"
            @mtd_aperturar="mtd_aperturar"
          />
        </CCol>
      </CRow>
    </div>
    <div v-else>
      <CRow>
        <div
          class="
            col-md-12
            d-flex
            align-items-center
            justify-content-between
            mb-3
            animate__animated animate__backInDown animate__slow
          "
        >
          <h6 class="font-weight-bold text-gray-900 mb-0">
            Fecha apertura: {{ date }}
          </h6>
          <button
            class="btn btn-lg btn-danger d-flex align-items-center"
            type="button"
            @click="mtd_close_bead"
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="ml-2">CERRAR CAJA</span>
          </button>
        </div>
      </CRow>

      <CRow>
        <!-- Lista de Ingresos-->
        <CCol lg="6" md="12" sm="12" xs="12">
          <div class="animate__animated animate__backInLeft">
            <CCol sm="12" md="12">
              <CCard border-color="success" class="py-1">
                <CCardHeader
                  class="d-flex align-items-center justify-content-between"
                  style="text-align: center; font-size: 18px; font-weight: bold"
                >
                  INGRESOS
                  <CButton
                    type="button"
                    :class="btnClasses"
                    @click="mtd_open_modal_incomes"
                    ><i class="fas fa-plus"></i>&nbsp; Agregar
                  </CButton>
                  <CButton
                    type="button"
                    :class="btnClasses"
                    @click="mtd_open_modal_charge"
                    ><i class="fas fa-plus"></i>&nbsp; Cobrar
                  </CButton>
                </CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      class="col-md-6 pr-0 text-center"
                    >
                      <span
                        class="
                          pointer
                          badge badge-warning
                          animate__animated
                          animate__pulse
                          animate__slow
                          animate__infinite
                          py-2
                        "
                        @click="mtd_viewdet_incomes(1)"
                        style="
                          font-size: 18.5px;
                          margin-top: 10px;
                          background: #26994d;
                          color: white;
                        "
                        >Total Efectivos: S/ {{ cp_total_incomes_cash }}</span
                      >
                    </CCol>
                    <CCol
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      class="col-md-6 pr-0 text-center"
                    >
                      <span
                        class="
                          pointer
                          badge badge-warning
                          animate__animated
                          animate__pulse
                          animate__slow
                          animate__infinite
                          py-2
                        "
                        @click="mtd_viewdet_incomes(2)"
                        style="
                          font-size: 18.5px;
                          margin-top: 10px;
                          background: #26994d;
                          color: white;
                        "
                        >Total Tarjetas y Depósitos: S/
                        {{ cp_total_incomes_deposits }}</span
                      >
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          </div>
        </CCol>

        <!-- Lista de Egresos-->
        <CCol lg="6" md="12" sm="12" xs="12">
          <div class="animate__animated animate__backInRight">
            <CCol sm="12" md="12">
              <CCard border-color="warning" class="py-1">
                <CCardHeader
                  class="d-flex align-items-center justify-content-between"
                  style="text-align: center; font-size: 18px; font-weight: bold"
                >
                  EGRESOS
                  <CButton
                    type="button"
                    class="btn btn-warning"
                    @click="mtd_open_modal_adelantos"
                    ><i class="fas fa-plus"></i>&nbsp; Acopios
                  </CButton>
                  <CButton
                    type="button"
                    class="btn btn-warning"
                    @click="mtd_open_modal_expense"
                    ><i class="fas fa-plus"></i>&nbsp; Otros egresos
                  </CButton>
                  <CButton
                    type="button"
                    class="btn btn-warning"
                    @click="mtd_open_modal_pagos"
                    ><i class="fas fa-plus"></i>&nbsp; Pagos
                  </CButton>
                </CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      class="col-md-6 pr-0 text-center"
                    >
                      <span
                        class="
                          pointer
                          badge badge-warning
                          animate__animated
                          animate__pulse
                          animate__slow
                          animate__infinite
                          py-2
                        "
                        @click="mtd_viewdet_expense(1)"
                        style="font-size: 18.5px; margin-top: 10px"
                        >Total de E. Efectivos: S/
                        {{ cp_total_expense_cash }}</span
                      >
                    </CCol>
                    <CCol
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      class="col-md-6 pr-0 text-center"
                    >
                      <span
                        class="
                          pointer
                          badge badge-warning
                          animate__animated
                          animate__pulse
                          animate__slow
                          animate__infinite
                          py-2
                        "
                        @click="mtd_viewdet_expense(2)"
                        style="font-size: 18.5px; margin-top: 10px"
                        >Total de E. Depósitos: S/
                        {{ cp_total_expense_deposits }}</span
                      >
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          </div>
        </CCol>

        <!-- Lista de Resultdos-->
        <CCol lg="12">
          <div
            class="col-md-12 animate__animated animate__bounceInUp container"
          >
            <div class="row">
              <div class="col-md-12 d-flex justify-content-around flex-wrap">
                <div>
                  <span
                    class="badge badge-secondary px-3 py-2 m-2"
                    style="font-size: 18px"
                    >Efectivo aperturado: S/
                    {{ parseFloat(bead.initial_balance).toFixed(2) }}</span
                  >
                </div>
                <!-- <div>
                  <span
                    class="badge badge-info px-3 py-2 m-2"
                    style="font-size: 18px"
                    >Anticipos: S/. {{ cp_anticipos_total }}</span
                  >
                </div> -->
                <div>
                  <span
                    class="badge badge-info px-3 py-2 m-2"
                    style="font-size: 18px"
                    >Ingresos Efectivo: S/. {{ cp_total_incomes_cash }}</span
                  >
                </div>
                <div>
                  <span
                    class="badge badge-info px-3 py-2 m-2"
                    style="font-size: 18px"
                    >Egresos Efectivo: S/. {{ cp_total_expense_cash }}</span
                  >
                </div>
                <div>
                  <span
                    class="badge badge-dark px-3 py-2 m-2"
                    style="font-size: 18px"
                    >Efectivo Final: S/. {{ cp_final_balance }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>

    <!-- modal confirm -->
    <cModalConfirm
      title="Aperturar caja"
      :boo_modal="modal_confirm_apertura.boo"
      :item="modal_confirm_apertura.item"
      @close_confirm_apertura="close_confirm_apertura"
      @mtd_commit_apertura="mtd_commit_apertura"
    ></cModalConfirm>

    <!-- modal nuevo ingreso -->
    <cModalImcome
      title="Nuevo ingreso"
      :boo_modal="modal_new_income.boo"
      :item="modal_new_income.item"
      :data_client="data_client"
      :bead="bead"
      @mtd_close_modal_income="mtd_close_modal_income"
      @mtd_commit_modal_income="mtd_commit_modal_income"
    >
    </cModalImcome>

    <!--  modal charge -->
    <cModalCharge
      title="Cobros"
      :boo_modal="modal_charge.boo"
      :item="modal_charge.bead"
      :data_charge="data_charge"
      @mtd_close_modal_charge="mtd_close_modal_charge"
      @mtd_commit_modal_charge="mtd_commit_modal_charge"
    ></cModalCharge>

    <!--  modal view incomes -->
    <cModalViewincome
      title="Lista de ingresos"
      :boo_modal="modal_view_income.boo"
      :charges="modal_view_income.temp"
      @mtd_close_modal_view_income="mtd_close_modal_view_income"
    ></cModalViewincome>

    <!-- EGRESOS -->
    <!--  modal pagos -->
    <cModalPayment
      title="Pagar"
      :boo_modal="modal_pagos.boo"
      :item="modal_pagos.bead"
      :data_purchase="data_purchase"
      @mtd_close_modal_pagos="mtd_close_modal_pagos"
      @mtd_commit_modal_pagos="mtd_commit_modal_pagos"
    ></cModalPayment>
    <!-- modal nuevo ingreso -->
    <cModalExpense
      title="Nuevo egreso"
      :boo_modal="modal_new_expense.boo"
      :item="modal_new_expense.item"
      :data_supplier="data_supplier"
      :bead="bead"
      @mtd_close_modal_expense="mtd_close_modal_expense"
      @mtd_commit_modal_expense="mtd_commit_modal_expense"
    >
    </cModalExpense>
     <!--  modal view expenses -->
    <cModalViewExpense
      title="Lista de egresos"
      :boo_modal="modal_view_expense.boo"
      :charges="modal_view_expense.temp"
      @mtd_close_modal_view_expense="mtd_close_modal_view_expense"
    ></cModalViewExpense>
     <!-- modal adelantos -->
    <cModalAdvacement
      title="Adelantos"
      :boo_modal="modal_new_advancecement.boo"
      :item="modal_new_advancecement.item"
      :data_supplier_all="data_supplier_all"
      :bead="bead"
      :lst_acopios="lst_acopios"
      @mtd_close_modal_advancement="mtd_close_modal_advancement"
      @mtd_commit_modal_advancement="mtd_commit_modal_advancement"
    >
    </cModalAdvacement>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import cModalConfirm from "../../components/shared/bead/cModalConfirm.vue";
import cModalImcome from "../../components/shared/bead/cModalImcome.vue";
import cModalCharge from "../../components/shared/bead/cModalCharge.vue";
import cModalViewincome from "../../components/shared/bead/cModalViewincome.vue";
/** egresos */
import cModalPayment from "../../components/shared/bead/cModalPayment.vue";
import cModalExpense from '../../components/shared/bead/cModalExpense.vue';
import cModalViewExpense from '../../components/shared/bead/cModalViewExpense.vue';
import cModalAdvacement from '../../components/shared/bead/cModalAdvacement.vue';

const fields = [
  { key: "Id", _style: "width:3%" },
  { key: "name", label: "Caja", _style: "width:20%;" },
  { key: "status", label: "Estado", _style: "width:5%;" },
];

export default {
  components: {
    CTableWrapper,
    cModalConfirm,
    cModalImcome,
    cModalCharge,
    cModalViewincome,
    cModalPayment,
    cModalExpense,
    cModalViewExpense,
    cModalAdvacement
  },
  data() {
    return {
      prefix: "bead",
      fields,
      bead: null,
      /** aperturar */
      data: [],
      myButtons: [
        {
          class: "btn-sm",
          color: "info",
          tooltip: "Abrir caja",
          action: "mtd_aperturar",
          icon: "fas fa-cash-register",
          show: {
            0: true,
            1: false,
          },
        },
      ],
      modal_confirm_apertura: {
        boo: false,
        item: [],
      },
      modal_new_income: {
        boo: false,
      },
      date: null,
      /** ingresos */
      data_charge: [],
      modal_charge: {
        boo: false,
        bead: null,
      },
      charges: [],
      modal_view_income: {
        boo: false,
        type: null,
        temp: [],
      },

      data_client: [],
      list_incomes: [],
      tot_incomes_cash: 0,
      tot_incomes_deposits: 0,
      /** egresos */
      data_purchase: [],
      modal_pagos: {
        boo: false,
        bead: null,
      },
      payments: [],
      modal_view_expense:{
        boo: false,
        type: null,
        temp: [],
      },
      modal_new_expense: {
        boo: false,
      },
      data_supplier:[],
      list_expenses: [],
      tot_expenses_cash: 0,
      tot_expenses_deposits: 0,

      otros_ingresos: 0,
      final_balance: 0,
      /** adelantos */
      lst_acopios:[],
      data_supplier_all:[],
      modal_new_advancecement:{
        boo: false,
      }
    };
  },
  computed: {
    /** cerrar */
    btnClasses() {
      return [`btn btn-${this.color || "success"}`];
    },
    cp_total_incomes_cash: function () {
      //? Total De Ingresos (Efectivo)
      let total_incomes_cash = 0.0;
      /** incomes */
      this.list_incomes.forEach((element) => {
        if (element.method_payment == 1) {
          total_incomes_cash += parseFloat(element.amount);
        }
      });

      /** charges */
      this.charges.forEach((element) => {
        if (element.method_pyment == 1) {
          total_incomes_cash += parseFloat(element.amount);
        }
      });

      this.tot_incomes_cash = parseFloat(total_incomes_cash).toFixed(2);
      return this.tot_incomes_cash;
    },
    cp_total_incomes_deposits: function () {
      //? Total De Ingresos (Depósitos)
      let total_incomes_deposits = 0.0;
      this.list_incomes.forEach((element) => {
        if (element.method_payment == 2 || element.method_payment == 3) {
          total_incomes_deposits += parseFloat(element.amount);
        }
      });
      /** charges */
      this.charges.forEach((element) => {
        if (element.method_pyment != 1) {
          total_incomes_deposits += parseFloat(element.amount);
        }
      });

      this.tot_incomes_deposits = parseFloat(total_incomes_deposits).toFixed(2);
      return this.tot_incomes_deposits;
    },
    cp_total_expense_cash: function () {
      let total_expe_cash = 0.0;
      /*** otros egresos */
      this.list_expenses.forEach((element) => {
        if (element.method_payment == 1) {
          total_expe_cash += parseFloat(element.amount);
        }
      });
      /** pagos */
      this.payments.forEach((element) => {
        if (element.method_pyment == 1) {
          total_expe_cash += parseFloat(element.amount);
        }
      });
      this.tot_expenses_cash = parseFloat(total_expe_cash).toFixed(2);

      return this.tot_expenses_cash;
    },
    cp_total_expense_deposits: function () {
      let total_expe_deposits = 0.0;
      /** otros egresos */
      this.list_expenses.forEach((element) => {
        if (element.method_payment != 1) {
          total_expe_deposits += parseFloat(element.amount);
        }
      });
      /** pagos */
      this.payments.forEach((element) => {
        if (element.method_pyment != 1) {
          total_expe_deposits += parseFloat(element.amount);
        }
      });
      this.tot_expenses_deposits = parseFloat(total_expe_deposits).toFixed(2);

      return this.tot_expenses_deposits;
    },
    cp_final_balance: function () {
      //* Inicial + Total Ingresos Efectivo + Pago de Cuota - Total Egresos Efectivo
      let final =
        parseFloat(this.bead.initial_balance) + //! Inicial
        parseFloat(this.tot_incomes_cash) + //! Total Ingresos Efectivo
        parseFloat(this.otros_ingresos) - //! Pago de Cuota
        parseFloat(this.tot_expenses_cash); //! Total de Egresos Efectivo
      this.final_balance = parseFloat(final).toFixed(2);
      this.bead.final_balance = this.final_balance;
      return this.final_balance;
    },
    cp_anticipos_total: function () {
      let antipo = 0;
      this.list_incomes.forEach((element) => {
        if (element.type == 1) {
          antipo += parseFloat(element.amount_total);
        }
      });
      return parseFloat(antipo).toFixed(2);
    },
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          // console.log(response);
          this.bead = response.bead;
          this.data = response.data;
          if (this.bead != null) {
            let last_date = this.bead.date_start.split(" ");
            this.date =
              last_date[0].substr(8, 2) +
              "/" +
              last_date[0].substr(5, 2) +
              "/" +
              last_date[0].substr(0, 4) +
              " " +
              last_date[1];
            this.data_client = response.data_client;
            this.list_incomes = response.list_incomes;
            this.data_charge = response.data_charge;
            this.charges = response.charges;
            /** egresos */
            this.data_purchase = response.data_purchase;
            this.payments = response.payments;
            this.data_supplier=response.data_supplier;
            this.list_expenses = response.list_expenses;
            /** acopios-adelanto */
            this.data_supplier_all = response.data_supplier_all;
            this.lst_acopios= response.lst_acopios;
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    /** aperturar */
    mtd_aperturar: function (item) {
      this.modal_confirm_apertura.boo = true;
      this.modal_confirm_apertura.item = item;
    },
    close_confirm_apertura: function () {
      this.modal_confirm_apertura.boo = false;
    },
    mtd_commit_apertura: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/store/" +
          this.modal_confirm_apertura.item.Id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          // console.log(response);
          let color = "success";
          let message = "";
          if (response.state == 0) {
            message = response.message;
            this.bead = response.bead;
          } else {
            color = "danger";
            message = response.message;
          }
          this.modal_confirm_apertura = {
            boo: false,
            item: [],
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    /** cerrar */
    mtd_close_bead: function () {
      console.log(this.bead);
      console.log(this.final_balance);
       this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/close",
        token: this.$store.getters.get__token,
        params:{
          bead_id: this.bead.id,
          final: this.final_balance
        }
      })
        .then((response) => {
        //  console.log(response);
         if (response.state == 0) {
          bus.$emit("alert", {
            color: 'success',
            message: response.message,
          });
          this.mtd_getdata();
         } else {
          bus.$emit("alert", {
            color: 'danger',
            message: response.message,
          });
         }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    /** */
    mtd_open_modal_incomes: function () {
      this.modal_new_income.boo = true;
    },
    mtd_close_modal_income: function () {
      this.modal_new_income.boo = false;
    },
    mtd_commit_modal_income: function (document) {
      this.list_incomes.push(document);
      this.mtd_close_modal_income();
    },
    /** cobros */
    mtd_open_modal_charge: function () {
      this.modal_charge.boo = true;
      this.modal_charge.bead = this.bead;
    },
    mtd_close_modal_charge: function (data) {
      this.modal_charge.boo = false;
      this.data_charge = data;
    },
    mtd_commit_modal_charge: function (document) {
      // this.list_incomes.push(document);
      // this.mtd_close_modal_income();
    },
    mtd_viewdet_incomes: function (type) {
      let temp = [];
      this.charges.forEach((element) => {
        if (type == 1) {
          if (element.method_pyment == 1) {
            temp.push(element);
          }
        } else {
          if (element.method_pyment != 1) {
            temp.push(element);
          }
        }
      });
       this.list_incomes.forEach((element) => {
        if (type == 1) {
          if (element.method_payment == 1) {
            temp.push(element);
          }
        } else {
          if (element.method_payment != 1) {
            temp.push(element);
          }
        }
      });
      this.modal_view_income = {
        boo: true,
        type: type,
        temp: temp,
      };
    },
    mtd_close_modal_view_income: function () {
      this.modal_view_income = {
        boo: false,
        type: null,
        temp: [],
      };
    },
    /** cobros */
    mtd_open_modal_pagos: function () {
      this.modal_pagos.boo = true;
      this.modal_pagos.bead = this.bead;
    },
    mtd_close_modal_pagos: function (data) {
      this.modal_pagos.boo = false;
      this.data_charge = data;
    },
    mtd_commit_modal_pagos: function () {},
    mtd_viewdet_expenses: function(type){
      let temp = [];
      this.payments.forEach((element) => {
        if (type == 1) {
          if (element.method_pyment == 1) {
            temp.push(element);
          }
        } else {
          if (element.method_pyment != 1) {
            temp.push(element);
          }
        }
      });
       this.list_expenses.forEach((element) => {
        if (type == 1) {
          if (element.method_payment == 1) {
            temp.push(element);
          }
        } else {
          if (element.method_payment != 1) {
            temp.push(element);
          }
        }
      });
      this.modal_view_expense = {
        boo: true,
        type: type,
        temp: temp,
      };
    },
    /** otros egresos */
    mtd_open_modal_expense: function(){
      this.modal_new_expense.boo = true;
    },
    mtd_close_modal_expense: function(){
      this.modal_new_expense.boo = false;
    },
    mtd_commit_modal_expense: function(expense){
      this.list_expenses.push(expense);
      this.mtd_close_modal_expense();
    },
     mtd_viewdet_expense: function (type) {
      let temp = [];
      this.payments.forEach((element) => {
        if (type == 1) {
          if (element.method_pyment == 1) {
            temp.push(element);
          }
        } else {
          if (element.method_pyment != 1) {
            temp.push(element);
          }
        }
      });
       this.list_expenses.forEach((element) => {
        if (type == 1) {
          if (element.method_payment == 1) {
            temp.push(element);
          }
        } else {
          if (element.method_payment != 1) {
            temp.push(element);
          }
        }
      });
      this.modal_view_expense = {
        boo: true,
        type: type,
        temp: temp,
      };
    },
    mtd_close_modal_view_expense: function () {
      this.modal_view_expense = {
        boo: false,
        type: null,
        temp: [],
      };
    },
    /** adelantos */
    mtd_open_modal_adelantos: function(){
      this.modal_new_advancecement.boo = true;
    },
    mtd_close_modal_advancement: function(lst_res,lst_acopios){
      this.modal_new_advancecement.boo = false;
      lst_res.forEach(element => {
        this.list_expenses.push(element);
      });
      this.lst_acopios=lst_acopios;
    },
    mtd_commit_modal_advancement: function(advancement){
      
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>