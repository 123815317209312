<template>
  <CModal
    :show.sync="boo_modal"
    :no-close-on-backdrop="true"
    :centered="false"
    title="Modal title 2"
    :size="'xl'"
    color="success"
  >
    <!-- <CRow>
      <CCol lg="12" md="12" sm="12" xs="12">
        <label class="radio-input">
          <input
            :id="'type'"
            type="radio"
            :value="'1'"
            v-model="document.type"
          />
          Registrar anticipo
        </label>
        <label class="radio-input pl-3">
          <input
            :id="'type'"
            type="radio"
            :value="'2'"
            v-model="document.type"
          />
          
        </label>
      </CCol>
    </CRow> -->
    <div v-if="document.type == 1">
      <CRow>
        <CCol lg="4" md="4" sm="12" xs="12">
          <label for="">Cliente</label>
          <v-select
            :options="data_client"
            v-model="document.client"
            placeholder="Seleccione cliente"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <CInput
            label="IGV"
            v-model="document.igv"
            placeholder="IGV (%)"
            v-on:keypress="isNumber($event)"
          ></CInput>
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <label for="">Método pago</label>
          <v-select
            :options="data_method_pyment"
            v-model="document.method_payment"
            placeholder="Seleccione un metodo"
          />
        </CCol>
        <CCol lg="2" md="2" sm="12" xs="12" v-if="cp_num_op">
          <CInput
            label="N. Operación"
            v-model="document.num_op"
            placeholder="Num. operación"
          ></CInput>
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol lg="6" md="6" sm="12" xs="12">
          <CTextarea
            label="Descripción"
            placeholder="Descripción de producto"
            v-model="item_det.description"
          ></CTextarea>
        </CCol>
        <CCol lg="2" md="2" sm="12" xs="12">
          <CInput
            label="Peso neto"
            v-model="item_det.neto"
            placeholder="Peso neto"
            v-on:keypress="isNumber($event)"
            @blur="mtd_out_focus"
          ></CInput>
        </CCol>
        <CCol lg="4" md="4" sm="12" xs="12">
          <label for="">Unidad</label>
          <br />
          <label class="radio-input">
            <input
              :id="'unit'"
              type="radio"
              :value="'KG'"
              v-model="item_det.unit"
            />
            KG
          </label>
          <label class="radio-input pl-3">
            <input
              :id="'unit'"
              type="radio"
              :value="'LBS'"
              v-model="item_det.unit"
            />
            LIBRA
          </label>
          <label class="radio-input pl-3">
            <input
              :id="'unit'"
              type="radio"
              :value="'UND'"
              v-model="item_det.unit"
            />
            UNIDAD
          </label>
          <label class="radio-input pl-3">
            <input
              :id="'other'"
              type="radio"
              :value="'OTROS'"
              v-model="item_det.unit"
            />
            OTROS
          </label>
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <CInput
            label="Precio unitario"
            v-model="item_det.price"
            placeholder="Precio unitario"
            v-on:keypress="isNumber($event)"
            @blur="mtd_out_focus"
          ></CInput>
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <CInput
            label="Subtotal"
            disabled
            v-model="cp_subtotal"
            placeholder="Sub total"
          ></CInput>
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <label for="">&nbsp;</label><br />
          <CButton
            :disabled="cp_button_add"
            @click="mtd_add_item"
            color="success"
            ><i class="fas fa-plus"></i>&nbsp;Agregar</CButton
          >
        </CCol>
      </CRow>
      <CRow v-if="document.detail.length > 0">
        <CCol lg="12" md="12" sm="12" xs="12"  class="position-relative table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <td>#</td>
                <td>Descripción</td>
                <td>Peso neto</td>
                <td>Precio</td>
                <td>Subtotal</td>
                <td>Acción</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(it, index) in document.detail" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ it.description }}</td>
                <td>{{ it.neto }} {{ it.unit }}</td>
                <td>{{ it.price }}</td>
                <td>{{ parseFloat(it.neto * it.price).toFixed(2) }}</td>
                <td>
                  <CButton
                    size="sm"
                    color="danger"
                    v-c-tooltip.hover="{
                      content: 'Eliminar',
                    }"
                    @click="mtd_del_item(index)"
                  >
                    <i class="fas fa-trash"></i>
                  </CButton>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4" style="text-align: right">
                  <strong> TOTAL:</strong>
                </td>
                <td>
                  <strong>{{ cp_total }}</strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </CCol>
      </CRow>
    </div>
    <div v-else-if="document.type == 2">normal</div>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close_modal_income" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close_modal_income" color="danger">Cancelar</CButton>
      <CButton
        @click="mtd_commit_modal_income"
        color="success"
        :disabled="cp_button_save"
        >Aceptar</CButton
      >
    </template>
  </CModal>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../../main";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "c-modal-income",
  components: { vSelect },
  props: {
    title: String,
    boo_modal: Boolean,
    item: {},
    data_client: [],
    size: String,
    bead: {},
  },
  data() {
    return {
      view: null,
      data_method_pyment:[
        {value:1,label:"Efectivo"},
        {value:2,label:"Tarjeta"},
        {value:3,label:"Transferencia"},
      ],
      document: {
        bead_id: null,
        type: 1,
        client: null,
        igv: "",
        detail: [],
        method_payment:null,
        num_op:null
      },
      item_det: {
        description: "",
        neto: "",
        unit: "",
        price: "",
      },
    };
  },
  computed: {
    cp_num_op: function(){
      if(this.document.method_payment == null) return false;
      if(this.document.method_payment.value == 1) return false;
      return true;
    },
    cp_subtotal: function () {
      let sub = 0.0;
      if (this.item_det.price > 0) {
        sub = parseFloat(this.item_det.price * this.item_det.neto).toFixed(2);
      }
      return sub;
    },
    cp_button_add: function () {
      if (
        this.item_det.description.length > 0 &&
        this.item_det.neto > 0 &&
        this.item_det.unit != "" &&
        this.item_det.price > 0
      )
        return false;
      return true;
    },
    cp_button_save: function () {
      if (
        this.document.type != null &&
        this.document.client != null &&
        this.document.igv > 0 &&
        this.document.detail.length > 0 &&
        this.document.method_payment != null
      )
        return false;
      return true;
    },
    cp_total: function () {
      let total = 0;
      let det = this.document.detail;
      det.forEach((element) => {
        total += parseFloat(element.price * element.neto);
      });
      return parseFloat(total).toFixed(2);
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_close_modal_income() {
      this.$emit("mtd_close_modal_income");
    },
    mtd_commit_modal_income() {
      this.document.bead_id = this.bead.id;
      this.post({
        url: this.$store.getters.get__url + "/bead/store/document",
        token: this.$store.getters.get__token,
        params: this.document,
      })
        .then((response) => {
          if (response.state == 0) {
            this.document = {
              bead_id: null,
              type: 1,
              client: null,
              igv: "",
              detail: [],
              method_payment:null,
              num_op:null
            };
            bus.$emit("alert", {
              color: "success",
              message: "GUARDADO CORRECTAMENTE!!",
            });
            this.$emit("mtd_commit_modal_income", response.document);

          } else {
            bus.$emit("alert", {
              color: "success",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "success",
            message: errors,
          });
        });
    },
    /** */
    mtd_out_focus: function () {
      this.item_det.neto =
        this.item_det.neto > 0
          ? parseFloat(this.item_det.neto).toFixed(2)
          : 0.0;
      this.item_det.price =
        this.item_det.price > 0
          ? parseFloat(this.item_det.price).toFixed(2)
          : 0.0;
    },
    mtd_add_item: function () {
      this.document.detail.push(this.item_det);
      bus.$emit("alert", {
        color: "success",
        message: "ITEM AGREGADO CORRECTAMENTE",
      });
      this.item_det = {
        description: "",
        neto: "",
        unit: "",
        price: "",
      };
    },
    mtd_del_item: function (pos) {
      let temp_det = [];
      this.document.detail.forEach((element, index) => {
        if (index != pos) {
          temp_det.push(element);
        }
      });
      this.document.detail = temp_det;
      bus.$emit("alert", {
        color: "success",
        message: "ITEM ELIMINADO CORRECTAMENTE",
      });
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>