<template>
  <CModal
    :show.sync="boo_modal"
    :no-close-on-backdrop="true"
    :centered="false"
    title="Modal title 2"
    :size="'xl'"
    color="success"
  >
    <div v-if="view == 'table'">
      <CRow>
        <CCol lg="12" md="12" sm="12" xs="12"  class="position-relative table-responsive">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Id venta</th>
                <th>Cliente</th>
                <th>Documento</th>
                <th>Total</th>
                <th>Pendiente</th>
                <th>Pagar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data_charge" :key="index">
                <td>{{ item.Id }}</td>
                <td>{{ item.client }}</td>
                <td>{{ item.document }}</td>
                <td>{{ item.total }}</td>
                <td>{{ item.pending }}</td>
                <th>
                  <CButton @click="mtd_set_charge(item)" color="info"
                    ><i class="fas fa-save"></i
                  ></CButton>
                </th>
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
    </div>
    <div v-else>
      <CRow>
        <CCol lg="6" sm="6" md="12" xs="12" class="text-left">
          <CButton size="sm" color="warning" @click="mtd_return_table">
            <i class="fas fa-angle-left"></i> Regresar
          </CButton>
        </CCol>
        <CCol lg="6" sm="6" md="12" xs="12" class="text-right">
          <CButton
            size="sm"
            color="info"
            v-c-tooltip.hover="{
              content: 'Registrar documento',
            }"
            @click="mtd_save"
            :disabled="cp_disabled_save"
          >
            <i class="fas fa-save"></i> Registrar
          </CButton>
        </CCol>
        <CCol lg="2" md="2" sm="6" xs="12">
          <CInput label="Pendiente" :disabled="true" v-model="charge.pending" />
        </CCol>
        <CCol lg="2" md="2" sm="6" xs="12">
          <CInput
            label="Monto a pagar"
            v-model="charge.amount"
            placeholder="Monto a pagar"
            v-on:keypress="isNumber($event)"
          />
        </CCol>
        <CCol lg="2" md="2" sm="6" xs="12">
          <CInput
            label="Saldo"
            :disabled="true"
            v-model="cp_saldo"
            v-on:keypress="isNumber($event)"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <label for="">Método pago</label>
          <v-select
            :options="data_method_pyment"
            v-model="charge.method_payment"
            placeholder="Seleccione un metodo"
          />
        </CCol>
        <CCol lg="2" md="2" sm="6" xs="12">
          <CInput
            label="Num. operación"
            placeholder="Num. operación"
            :disabled="cp_num_op"
            v-model="charge.num_ope"
            v-on:keypress="isNumber($event)"
          />
        </CCol>
      </CRow>
    </div>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="success">Cancelar</CButton>
      <!-- <CButton @click="mtd_commit" color="info">Aceptar</CButton> -->
    </template>
  </CModal>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { mapActions } from "vuex";
import { bus } from "../../../main";

export default {
  name: "c-bead-charge",
  components: { vSelect },
  data() {
    return {
      view: "table",
      charge: {
        bead_id:"",
        sale_id: "",
        pending: "",
        amount: "",
        method_payment: null,
        num_ope: "",
      },
      data_method_pyment: [
        { value: 1, label: "Efectivo" },
        { value: 2, label: "Tarjeta" },
        { value: 3, label: "Transferencia" },
      ],
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: {},
    data_charge: [],
  },
  computed: {
    cp_saldo: function () {
      let res = 0;
      if (this.charge.amount > 0) {
        res = parseFloat(this.charge.pending - this.charge.amount);
      }
      return parseFloat(res).toFixed(2);
    },
    cp_num_op: function () {
      if (this.charge.method_payment == null) return true;
      if (this.charge.method_payment.value == 1) return true;
      return false;
    },
    cp_disabled_save: function () {
      if (this.charge.amount > 0) {
        if (
          this.charge.method_payment != null &&
          this.charge.method_payment.value == 1
        ) {
          return false;
        } else if (
          this.charge.method_payment != null &&
          this.charge.method_payment.value != 1
        ) {
          if (this.charge.num_ope.length > 0) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_close() {
      this.$emit("mtd_close_modal_charge",this.data_charge);
    },
    mtd_commit() {
      this.$emit("mtd_commit_modal_charge");
    },
    /** */
    mtd_set_charge: function (col) {
      this.view = "charge";
      this.charge.sale_id = col.Id;
      this.charge.pending = col.pending;
      this.charge.amount = "";
      this.charge.bead_id = this.item.id;
    },
    mtd_return_table: function () {
      this.view = "table";
      this.charge = {
        bead_id:"",
        sale_id: "",
        pending: "",
        amount: "",
        method_payment: null,
        num_ope: "",
      };
    },
    mtd_save: function () {
      this.post({
        url: this.$store.getters.get__url + "/bead/store/charge",
        token: this.$store.getters.get__token,
        params: this.charge
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
                color: "success",
                message: "COBRO REGISTRADO CORECTAMENTE!!",
            });
            this.data_charge.forEach(element => {
                if(element.Id == this.charge.sale_id){
                    element.pending = parseFloat(element.pending - this.charge.amount).toFixed(2);
                }
            });
            this.mtd_return_table();
          } else {
            bus.$emit("alert", {
                color: "danger",
                message: response.message,
            });
          }
          
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>